// import packages
import React, {useState} from "react";
import {connect} from 'react-redux';
import isEmail from "validator/es/lib/isEmail";

//Import assets
import '../assets/styles/containers/contacts.scss';
import {CallIcon,LocationIcon,SMSIcon} from "../assets/images";
import {LoadingOutlined} from "@ant-design/icons";

//Import Components
import {InputGroup} from "../components/uiElements/inputGroup";

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {SendEmail} from "../redux/actions";
import {FullscreenControl, Map, Placemark, TypeSelector, YMaps} from "@pbe/react-yandex-maps";
import Subscribe from "../components/Subscribe";
import {useEffect} from "react";
import ContactBlock from "../components/conatctBlock/ContactBlock";


function Contact(props) {
    const {contacts,requestLoading,staticTexts} = props

    const mapData = {
        center: [contacts?.latitude, contacts?.longitude],
        zoom: 16,
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <div className={'contact-wrapper'}>
        <div className={'top-block'}>
            <div className={'info-block'}>
                <div className={'info-title'}>
                    {staticTexts?.contact_info_title}
                </div>
                <div className={'email-block'}>
                    <span className={'blocks-title'}> <SMSIcon/> {staticTexts?.contact_email_title}</span>
                    <a href={`mailto:${contacts?.email}`} className={'information'}>
                        {contacts?.email}
                    </a>
                </div>
                <div className={'number-block'}>
                    <span className={'blocks-title'}> <CallIcon/> {staticTexts?.contact_number_title}</span>
                    <a href={`tel:${contacts?.overallNumber}`} className={'information'}>
                        <span>{staticTexts?.contact_first_number}</span> {contacts?.overallNumber}
                    </a>
                    <a href={`tel:${contacts?.salesNumber}`} className={'information'}>
                        <span>{staticTexts?.contact_second_number}</span> {contacts?.salesNumber}
                    </a>
                    <a href={`tel:${contacts?.maintenanceNumber}`} className={'information'}>
                        <span>{staticTexts?.contact_third_number}</span> {contacts?.maintenanceNumber}
                    </a>
                </div>
                <div className={'address-block'}>
                    <span className={'blocks-title'}> <LocationIcon/> {staticTexts?.contact_address_title}</span>
                    <div className={'information'}>
                        {contacts?.address}
                    </div>
                </div>
            </div>
            <div className={'contact-map-block'}>
                <YMaps query={{
                    apikey: '83841bdc-892a-431f-a9ff-b378ef38c6a0',
                    lang: 'en'
                }}>
                    <Map defaultState={mapData} cursors={"inherit"} className={'yandexMap'}>
                        <Placemark
                            geometry={[contacts?.latitude, contacts?.longitude]}
                            classname={'customIcon'}
                        />
                        <FullscreenControl options={{
                            position: {
                                bottom: 28,
                                right: 26,
                            },
                        }}/>
                        <TypeSelector options={{
                            float: 'right'
                        }}/>
                    </Map>
                </YMaps>
            </div>
        </div>
        <ContactBlock/>
        <Subscribe requestLoading={requestLoading} staticTexts={staticTexts}/>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    SendEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
